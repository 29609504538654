
const DocMenuConfig = [
  {
    pages: [
      {
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/general/home.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "news",
        route: "/news",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      
    ],
  },
  {
    heading: "Bo'limlar",
    route: "",
    pages:[
      {
        heading: "categoryListing",
        route: "/categories",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "subcategoryListing",
        route: "/subcategories",
        svgIcon: "media/icons/duotune/general/gen020.svg",
        fontIcon: "bi-person",
      },
      {
        heading: "languages",
        route: "/languages",
        svgIcon: "media/icons/duotune/general/lang.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "tags",
        route: "/tags",
        svgIcon: "media/icons/duotune/general/tag.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "social",
        route: "/social",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "parametrs",
        route: "/parametrs",
        svgIcon: "media/icons/duotune/general/gen026.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "translateListing",
        route: "/translate",
        svgIcon: "media/icons/duotune/general/gen028.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "users",
        route: "/users",
        svgIcon: "media/icons/duotune/general/gen029.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "sections",
        route: "/sections",
        svgIcon: "media/icons/duotune/general/gen037.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "questions",
        route: "/questions",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ]
  },
];



export default DocMenuConfig;
