
import { computed, defineComponent, onMounted } from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { initImgPath } from "@/core/helpers/imgpath";
export default defineComponent({
  name: "topbar",
  components: {
    KTSearch,
    KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup(){
    const store = useStore()

    const user = computed(()=>{
      return store.getters.currentUser
    })

    const countNotification = computed(()=>{
      return store.getters.notificationCount
    })

    // onMounted(()=>{
      
    // })

    return{
      countNotification, user, initImgPath
    }
  }
});
